import _, { groupBy, map, sortBy } from "lodash";
import moment, { Moment } from "moment";
import { PercentCrop } from "react-image-crop";
import { VendorBooking, VendorOrder } from "./types";

export const getOrdersFromBookings = (
  bookings: VendorBooking[],
): VendorOrder[] => {
  return sortBy(
    map(
      groupBy(bookings, (booking: VendorBooking) => booking.orderNumber),
      (bookings, orderNumber) =>
        ({
          orderNumber: parseInt(orderNumber, 10),
          bookings: sortBy(bookings, (booking) => booking.listingTitle),
          itemCount: bookings.length,
          client: `${bookings[0].clientUsername} (${bookings[0].clientEmail})`,
          deliveryType: bookings[0].deliveryType,
        }) as VendorOrder,
    ),
    (order: VendorOrder) => order.orderNumber,
  );
};

export const getReservationsFromBookings = (
  bookings: Record<string, any>[],
): Record<string, any>[] => {
  return sortBy(
    map(
      groupBy(bookings, (booking: Record<string, any>) => booking.orderNumber),
      (bookings, orderNumber) => ({
        orderNumber: parseInt(orderNumber, 10),
        bookings: _.map(
          sortBy(bookings, (booking) => booking.listingTitle),
          (booking) => {
            return {
              ...booking,
              listingTitle: booking.listing?.name,
            };
          },
        ),
        itemCount: bookings.length,
        listingTitle: bookings[0].listing.name,
        client: `${bookings[0].clientName} (${bookings[0].clientContact})`,
        notes: bookings[0].notes,
      }),
    ),
    (order: Record<string, any>) => order.orderNumber,
  );
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.slice(0, 2)}`,
  };
};
export const getCroppedImg = (
  image: HTMLImageElement,
  crop: PercentCrop,
  asBlob: boolean,
): Promise<Blob | string | null> => {
  const canvas = document.createElement("canvas");
  canvas.width = (crop.width / 100) * image.width;
  canvas.height = (crop.height / 100) * image.height;
  const ctx = canvas.getContext("2d");

  if (ctx) {
    ctx.drawImage(
      image,
      (crop.x / 100) * image.naturalWidth,
      (crop.y / 100) * image.naturalHeight,
      (crop.width / 100) * image.naturalWidth,
      (crop.height / 100) * image.naturalHeight,
      0,
      0,
      canvas.width,
      canvas.height,
    );

    return new Promise((resolve, reject) => {
      if (asBlob) {
        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          1.0,
        );
      } else {
        resolve(canvas.toDataURL());
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      reject(null);
    });
  }
};

export const getCroppedImageDataUrl = async (
  img: HTMLImageElement,
  crop: PercentCrop,
  onSuccess: (newDataURL: string) => void,
) => {
  try {
    const croppedImageDataUrl = (await getCroppedImg(img, crop, false)) as
      | string
      | null;
    if (croppedImageDataUrl) {
      onSuccess(croppedImageDataUrl);
    }
  } catch (error) {
    console.warn("Error getting the cropped image data URL", error);
  }
};

export const getCroppedImageBlob = async (
  img: HTMLImageElement,
  crop: PercentCrop,
  onSuccess: (croppedImageData: Blob) => Promise<void>,
) => {
  try {
    const croppedImageData = (await getCroppedImg(
      img,
      crop,
      true,
    )) as Blob | null;
    if (croppedImageData) {
      await onSuccess(croppedImageData);
    }
  } catch (error) {
    console.warn("Error getting the cropped image data blob", error);
  }
};

export const getISODatesBetween = (startDate: Moment, endDate: Moment) => {
  const dayCount = moment.duration(endDate.diff(startDate)).as("days");
  return [
    startDate.format("YYYY-MM-DD"),
    ..._.times(dayCount, (currentCount) =>
      startDate.add(1, "days").format("YYYY-MM-DD"),
    ),
  ];
};
