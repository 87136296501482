import type { components, paths } from "./api/apiSchema-1.7.0";

export type VendorBooking = components["schemas"]["VendorBooking"];

export type CurrentVendorBookingResponse =
  paths["/vendor-bookings/current/"]["get"]["responses"]["200"]["content"]["application/json"]["data"];

export type DeliveryType =
  components["schemas"]["VendorBooking"]["deliveryType"];
export type PaymentStatus = components["schemas"]["Booking"]["status"];

export type GetListingsResponse =
  paths["/rental-items/"]["get"]["responses"]["200"]["content"]["application/json"]["data"];

export type ConversationsResponse =
  paths["/conversations/"]["get"]["responses"]["200"]["content"]["application/json"]["data"];

export type VendorStockUnitAvailabilitiesResponse =
  paths["/vendor-stock-unit-availabilities/"]["get"]["responses"]["200"]["content"]["application/json"]["data"];

export type Listing = components["schemas"]["RentalItem"];
export type Conversation = components["schemas"]["Conversation"];
export type ConversationMessage = components["schemas"]["Message"];
export type VendorStockUnitAvailabilityInformation =
  components["schemas"]["VendorStockUnitAvailabilityInformation"];
export type UserPublicInformation =
  components["schemas"]["UserPublicInformation"];
export type ListingImageMeta =
  components["schemas"]["RentalItem"]["imagesMeta"];
export type ListingImage = {
  id: string;
  url: string;
  thumbnailUrls: string[];
  name?: string;
};

export type ListingImageBody = {
  imageFile: Blob;
};

export type ListingStatus = Listing["status"];
export type VendorOrder = {
  orderNumber: number;
  bookings: VendorBooking[];
  itemCount: number;
  client: string;
  deliveryType: DeliveryType;
};

export type VendorOrderWithRepresentativeBooking = VendorOrder & {
  firstRepresentativeBooking: VendorBooking;
};

export enum CurrentVendorBookingDirection {
  outgoing,
  returning,
}

export enum OrderType {
  KeeneeOrder,
  VendorReservation,
}
