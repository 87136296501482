import { styled } from "@mui/material";
import { DataGridPremium, gridClasses } from "@mui/x-data-grid-premium";

// DataGridPremium component with a bunch of styles applies to make it suitable for using
// in the availability calendar
export const CalendarStyledDataGridPremium = styled(DataGridPremium)(
  ({ theme }) => ({
    "& .MuiDataGrid-cell": {
      borderRight: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .date-header .MuiDataGrid-columnHeaderTitleContainerContent": {
      flexDirection: "column",
    },
    "& .MuiDataGrid-aggregationColumnHeaderLabel": {
      display: "none",
    },
    ".MuiDataGrid-pinnedRows .MuiDataGrid-footerCell": {
      height: "100%",
      pl: "44px",
      color: "text.primary",
    },
    "& .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedLeft":
      {
        backgroundColor: "white",
      },
    "& .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell--pinnedLeft":
      {
        backgroundColor: "white",
      },
    "& .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row.Mui-selected:hover .MuiDataGrid-cell--pinnedLeft":
      {
        backgroundColor: "white",
      },
    "& .MuiDataGrid-pinnedRows .MuiDataGrid-row:last-child .MuiDataGrid-cell": {
      borderBottom: 2,
      borderBottomColor: "black",
    },
    "& .availability-cell": {
      paddingLeft: 0,
      paddingRight: 0,
      display: "flex",
      gap: "2px",
      spacing: "2px",
    },
    [`& .${gridClasses.row}:hover`]: {
      backgroundColor: "transparent",
    },
    [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
      outline: "transparent",
    },
    [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]:
      {
        outline: "none",
      },
  }),
);
