import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, CircularProgress, Grid, Tab } from "@mui/material";
import { useMemo, useState } from "react";
import { ListingCard } from "../components/ListingCard";
import { ListingEditDialog } from "../components/ListingEditDialog";
import { PageHeader } from "../components/PageHeader";
import { useGetListingsQuery } from "../store/apiSlice";
import { Listing } from "../types";

export function Listings() {
  const [status, setStatus] = useState("published");
  const [selectedListingId, setSelectedListingId] = useState<string | null>(
    null,
  );

  const handleChange = (event: React.SyntheticEvent, newStatus: string) => {
    setStatus(newStatus);
  };

  const {
    allListings,
    publishedListings,
    underReviewListings,
    rejectedListings,
    archivedListings,
    isLoading,
    isSuccess,
    isError,
  } = useGetListingsQuery(undefined, {
    selectFromResult: ({ data, isLoading, isError, isSuccess }) => {
      return {
        allListings: data,
        publishedListings: data?.filter(
          (listing) => listing.status === "published",
        ),
        underReviewListings: data?.filter(
          (listing) => listing.status === "edited_draft",
        ),
        rejectedListings: data?.filter(
          (listing) => listing.status === "edits_rejected",
        ),
        archivedListings: data?.filter(
          (listing) => listing.status === "archived",
        ),
        isLoading,
        isError,
        isSuccess,
      };
    },
  });

  const handleEditListing = (listingId: string) => {
    setSelectedListingId(listingId);
  };

  const handleCloseListingEditDialog = () => {
    setSelectedListingId(null);
  };

  const selectedListing = useMemo(() => {
    if (selectedListingId) {
      return (
        allListings?.find(
          (listing: Listing) => listing.id === selectedListingId,
        ) || null
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedListingId]);

  return (
    <TabContext value={status}>
      <Grid item xs={12}>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <PageHeader>Listings</PageHeader>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange}>
                <Tab
                  label={
                    allListings?.length !== undefined
                      ? `All (${allListings?.length})`
                      : "All"
                  }
                  value="all"
                />
                <Tab
                  label={
                    publishedListings?.length !== undefined
                      ? `Published (${publishedListings?.length})`
                      : "Published"
                  }
                  value="published"
                />
                <Tab
                  label={
                    underReviewListings?.length !== undefined
                      ? `Under review (${underReviewListings?.length})`
                      : "Under review"
                  }
                  value="edited_draft"
                />
                <Tab
                  label={
                    rejectedListings?.length !== undefined
                      ? `Rejected (${rejectedListings?.length})`
                      : "Rejected"
                  }
                  value="edits_rejected"
                />
                <Tab
                  label={
                    archivedListings?.length !== undefined
                      ? `Archived (${archivedListings?.length})`
                      : "Archived"
                  }
                  value="archived"
                />
              </TabList>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {isLoading && (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}

            {!isLoading && (
              <TabPanel value="all">
                <Grid container spacing={2}>
                  {allListings?.map((listing) => (
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                      <ListingCard
                        listing={listing}
                        onEditListing={handleEditListing}
                      />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            )}

            {!isLoading && (
              <TabPanel value="published">
                <Grid container spacing={2}>
                  {publishedListings?.map((listing) => (
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                      <ListingCard
                        listing={listing}
                        onEditListing={handleEditListing}
                      />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            )}

            {!isLoading && (
              <TabPanel value="edited_draft">
                <Grid container spacing={2}>
                  {underReviewListings?.map((listing) => (
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                      <ListingCard
                        listing={listing}
                        onEditListing={handleEditListing}
                      />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            )}

            {!isLoading && (
              <TabPanel value="edits_rejected">
                <Grid container spacing={2}>
                  {rejectedListings?.map((listing) => (
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                      <ListingCard
                        listing={listing}
                        onEditListing={handleEditListing}
                      />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            )}

            {!isLoading && (
              <TabPanel value="archivedd">
                <Grid container spacing={2}>
                  {archivedListings?.map((listing) => (
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                      <ListingCard
                        listing={listing}
                        onEditListing={handleEditListing}
                      />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </Grid>
      {selectedListing && (
        <ListingEditDialog
          listing={selectedListing}
          handleClose={handleCloseListingEditDialog}
        />
      )}
    </TabContext>
  );
}
