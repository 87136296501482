import { Box } from "@mui/material";
import { Conversation } from "../types";
import { ConversationBox } from "./ConversationBox";

interface ConversationStreamProps {
  conversations: Conversation[] | undefined;
  readConversationIds: string[];
  onSelectConversation: (conversationId: string) => void;
  selectedConversationId: string | null;
}

export const ConversationStream: React.FC<ConversationStreamProps> = ({
  conversations,
  readConversationIds,
  onSelectConversation,
  selectedConversationId,
}) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        overflowY: "auto",
        pb: 6,
        background: "white",
      }}
    >
      {conversations?.map((conversation, index) => (
        <ConversationBox
          conversation={conversation}
          read={
            conversation.read || readConversationIds.includes(conversation.id)
          }
          onSelectConversation={onSelectConversation}
          isSelected={conversation.id === selectedConversationId}
        />
      ))}
    </Box>
  );
};
