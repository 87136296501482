import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";

interface ListingOptionSelectorProps {
  allStockUnits: Record<string, any>[];
  onListingUnitSelected: (rentalItemId: string, unitId: string) => void;
  handleConfirm?: (rentalItemId: string, unitId: string) => void;
  isHorizontal?: boolean;
  itemsSelected?: Record<string, any>[];
  requireCompleteChoice?: boolean;
}

export const ListingOptionSelector: React.FC<ListingOptionSelectorProps> = ({
  allStockUnits,
  onListingUnitSelected,
  isHorizontal = false,
  requireCompleteChoice = false,
  itemsSelected = [],
  handleConfirm,
}) => {
  const [listingValue, setListingValue] = useState<string>("");
  const [listingOptionsValue, setListingOptionsValue] = useState<string>("");
  const [isCompleteChoice, setIsCompleteChoice] = useState(false);

  const listingUnitData = useMemo(() => {
    const byListing = Object.values(
      _.mapValues(
        _.groupBy(
          _.filter(allStockUnits, (unit) => unit.isPublic),
          (unit) => unit.rentalItem,
        ),
        (units) => ({
          units: units,
          title: units[0].rentalItemTitle,
          id: units[0].rentalItem,
          options: _.flatten(units.map((unit) => unit.selectableOptions)),
        }),
      ),
    );
    return byListing;
  }, [allStockUnits]);

  const listingAvailableOptions = useMemo(() => {
    const listingData = listingUnitData.find((row) => row.id === listingValue);
    const selectedMatchingItems = _.filter(
      itemsSelected,
      (item) => item.rentalItemId === listingValue,
    );

    if (listingData) {
      const data = _.sortBy(
        _.filter(
          _.uniqBy(
            listingData.options,
            (option) => `${option.name}: ${option.value}`,
          ),

          (option) => {
            return (
              selectedMatchingItems.find(
                (selectedItem) =>
                  selectedItem.option === `${option.name}: ${option.value}`,
              ) === undefined
            );
          },
        ),
        (option) => `${option.name}: ${option.value}`,
      );
      return data;
    }
  }, [listingUnitData, itemsSelected, listingValue]);

  useEffect(() => {
    const listingData = listingUnitData.find((row) => row.id === listingValue);
    const listingOptions = listingData ? listingData.options : [];
    if (requireCompleteChoice) {
      if (
        listingValue &&
        (listingOptions === undefined ||
          listingOptions.length === 0 ||
          (listingOptions.length > 0 && listingOptionsValue))
      ) {
        onListingUnitSelected(listingValue, listingOptionsValue);
        setIsCompleteChoice(true);
      } else {
        onListingUnitSelected("", "");
        setIsCompleteChoice(false);
      }
    } else {
      onListingUnitSelected(listingValue, listingOptionsValue);
      setIsCompleteChoice(true);
    }
  }, [listingValue, listingOptionsValue]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        maxWidth: "100%",
        flexGrow: 1,
      }}
    >
      <Grid container>
        <Grid item xs={isHorizontal ? 12 : 10}>
          <Grid
            container
            gap={isHorizontal ? 0 : 1}
            spacing={isHorizontal ? 2 : 0}
            sx={{ flexDirection: isHorizontal ? "row" : "column" }}
          >
            <Grid item xs={isHorizontal ? 5 : 12} sx={{ display: "flex" }}>
              <TextField
                select
                label="Listing"
                size="small"
                sx={{ width: "100%" }}
                value={listingValue}
                onChange={(event) => {
                  const listingData = listingUnitData.find(
                    (row) => row.id === event.target.value,
                  );
                  const listingOptions = listingData ? listingData.options : [];
                  setListingValue(event.target.value);
                  if (
                    listingOptions === undefined ||
                    listingOptions.length === 0
                  ) {
                    setListingOptionsValue("");
                  }
                }}
              >
                {listingUnitData
                  ? listingUnitData.map((row) => (
                      <MenuItem key={row.id} value={row.id}>
                        {row.title}
                      </MenuItem>
                    ))
                  : []}
              </TextField>
            </Grid>
            <Grid item xs={isHorizontal ? 5 : 12} sx={{ display: "flex" }}>
              <TextField
                select
                label="Option"
                size="small"
                sx={{ width: "100%" }}
                onChange={(event) => {
                  setListingOptionsValue(event.target.value);
                }}
                disabled={
                  listingValue === "" ||
                  !listingAvailableOptions ||
                  listingAvailableOptions.length === 0
                }
                value={listingOptionsValue}
              >
                {listingAvailableOptions
                  ? listingAvailableOptions.map((option) => (
                      <MenuItem
                        key={`${option.name}: ${option.value}`}
                        value={`${option.name}: ${option.value}`}
                      >
                        {`${option.name}: ${option.value}`}
                      </MenuItem>
                    ))
                  : []}
              </TextField>
            </Grid>
            {isHorizontal && (
              <Grid item xs={2} sx={{ alignContent: "center" }}>
                <Button
                  variant="contained"
                  sx={{ width: "100%" }}
                  disabled={!isCompleteChoice}
                  onClick={() => {
                    if (handleConfirm) {
                      handleConfirm(listingValue, listingOptionsValue);
                    }
                    setListingValue("");
                    setListingOptionsValue("");
                    setIsCompleteChoice(false);
                  }}
                >
                  Add
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isHorizontal && (
          <Grid item xs={2} sx={{ alignContent: "center" }}>
            <Button
              variant="text"
              color="info"
              sx={{
                minWidth: "48px",
                "&:hover": { backgroundColor: "transparent" },
              }}
              disabled={listingValue === ""}
              onClick={() => {
                setListingValue("");
                setListingOptionsValue("");
              }}
            >
              <CancelIcon />
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
