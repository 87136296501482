import { useEffect, useRef } from "react";
import { useLocalStorage } from "usehooks-ts";

export const useAutoRefresh = (callback: (() => void) | null | undefined) => {
  const [autoRefresh, setAutoRefresh] = useLocalStorage("auto-refresh", true);

  // keep a reference to the timer between component refreshes
  let autoRefreshInterval = useRef<NodeJS.Timer | null>(null);

  // 30 sec
  const autoRefreshIntervalMSec = 30000;

  useEffect(() => {
    const setUpAutoRefresh = () => {
      if (autoRefreshInterval.current === null) {
        autoRefreshInterval.current = setInterval(() => {
          try {
            if (callback) {
              callback();
            }
          } catch (error) {
            console.error("Error running the auto-refresh callback", error);
          }
        }, autoRefreshIntervalMSec);
      }
    };
    const cancelAutoRefresh = () => {
      if (autoRefreshInterval.current) {
        clearInterval(autoRefreshInterval.current);
        autoRefreshInterval.current = null;
      }
    };

    // if auto-refresh has been disabled cancel the timer
    if (autoRefresh) {
      setUpAutoRefresh();
    } else {
      cancelAutoRefresh();
    }

    // Clear the auto-refresh when the component unmounts
    return () => {
      cancelAutoRefresh();
    };
  }, [autoRefresh]);

  return {
    autoRefresh,
    setAutoRefresh,
  };
};
