import CloseIcon from "@mui/icons-material/Close";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PersonIcon from "@mui/icons-material/Person";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { useGetVendorReservationQuery } from "../store/apiSlice";
import { VendorBooking } from "../types";
import { getReservationsFromBookings } from "../utils";
import { DataSectionError } from "./DataSectionError";
import { DataSectionLoading } from "./DataSectionLoading";
import { VendorReservationLineItemsTable } from "./VendorReservationLineItemsTable";

interface VendorOrderDetailsProps {
  orderNumber: number;
  onClose: () => void;
}

export const VendorReservationDetails: React.FC<VendorOrderDetailsProps> = ({
  orderNumber,
  onClose,
}) => {
  // TODO: retrieve order from API here

  // Get order data
  const { orders, isLoading, isError, isSuccess } =
    useGetVendorReservationQuery(orderNumber, {
      selectFromResult: ({ data, isLoading, isError, isSuccess }) => {
        return {
          isLoading,
          isError,
          isSuccess,
          orders:
            data === undefined
              ? []
              : getReservationsFromBookings(data as VendorBooking[]),
        };
      },
    });

  const order = useMemo(() => {
    if (isLoading || !isSuccess) {
      return undefined;
    }
    return orders.find(
      (order: Record<string, any>) => order.orderNumber === orderNumber,
    ) as Record<string, any>;
  }, [orders, orderNumber, isLoading, isSuccess]);

  return (
    <Box sx={{ padding: 4, minWidth: "30vw", height: "100%" }}>
      {isLoading && <DataSectionLoading />}
      {!isLoading && isError && <DataSectionError />}
      {!isLoading && isSuccess && order && (
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
          gap={4}
        >
          <Grid item sx={{ display: "flex", flexDirection: "column" }} gap={4}>
            <Grid container>
              <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" sx={{ mr: 1 }}>
                  Reservation ID: {order.orderNumber}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <IconButton
                  onClick={() => {
                    onClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container>
              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={order.client} />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <EditNoteIcon />
                  </ListItemIcon>
                  <ListItemText primary={order.notes} />
                </ListItem>
              </List>
            </Grid>

            <Grid container>
              <VendorReservationLineItemsTable order={order} />
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
            {/* <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<MailOutlineIcon />}
            >
              Message the client
            </Button> */}
          </Box>
        </Grid>
      )}
    </Box>
  );
};
