import React from "react";

import LogoutIcon from "@mui/icons-material/Logout";
import {
  Avatar,
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useAutoRefresh } from "../hooks/useAutoRefresh";
import { selectUser } from "../store/userSlice";

export const UserAccountMenu = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const user = useSelector(selectUser);

  const { autoRefresh, setAutoRefresh } = useAutoRefresh(null);

  return (
    <Box sx={{ display: "flex" }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={user?.username} src={user?.avatarImage || undefined} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {/* <MenuItem key="profile" onClick={handleCloseUserMenu}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <Link
            component={RouterLink}
            to="/profile"
            underline="none"
            color="inherit"
          >
            Profile
          </Link>
        </MenuItem>
        <Divider /> */}
        <MenuItem key="autorefresh">
          <FormControlLabel
            control={
              <Switch
                checked={autoRefresh}
                onChange={(event) => {
                  setAutoRefresh(event.target.checked);
                }}
              />
            }
            label="Refresh data every 30s"
          />
        </MenuItem>
        <Divider />
        <MenuItem key="logout" onClick={handleCloseUserMenu}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <Link
            component={RouterLink}
            to="/logout"
            underline="none"
            color="inherit"
          >
            Log out
          </Link>
        </MenuItem>
      </Menu>
    </Box>
  );
};
