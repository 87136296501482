import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  TextField,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from "react-hook-form";
import { PercentCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { useAddListingImageMutation } from "../store/apiSlice";
import { ListingImageMeta } from "../types";
import { getCroppedImageBlob } from "../utils";
import { ImageCropperDialog } from "./ImageCropperDialog";
import { ListingEditFormValues } from "./ListingEditDialog";

interface ListingEditImagesProps {
  register: UseFormRegister<ListingEditFormValues>;
  unregister: UseFormUnregister<ListingEditFormValues>;
  errors: FieldErrors<ListingEditFormValues>;
  watch: UseFormWatch<ListingEditFormValues>;
  setValue: UseFormSetValue<ListingEditFormValues>;
  imagesMeta: ListingImageMeta;
}

export const ListingEditImages: React.FC<ListingEditImagesProps> = ({
  errors,
  register,
  unregister,
  watch,
  setValue,
  imagesMeta,
}) => {
  const [imageIds, setImageIds] = useState<string[]>(
    imagesMeta.map((imageMeta) => imageMeta.id),
  );
  const [currentImagesMeta, setCurrentImagesMeta] =
    useState<ListingImageMeta>(imagesMeta);

  const [completedCrop, setCompletedCrop] = useState<PercentCrop | null>(null);

  const [uploadedImages, setUploadedImages] = useState<ImageListType>([]);
  const [isUploadError, setIsUploadError] = useState<boolean>(false);

  const newImageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    register("images");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    addListingImage,
    {
      isLoading: isUploadingImage,
      isError: isUploadingError,
      isSuccess: isUploadingSuccess,
      error: imageUploadError,
    },
  ] = useAddListingImageMutation();

  const onNewImageSelected = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined,
  ) => {
    if (imageList[0].dataURL) {
      setIsUploadError(false);
      const img = new Image();
      img.src = imageList[0].dataURL;
      img.onload = () => {
        newImageRef.current = img;
        setUploadedImages(imageList);
      };
    }
  };

  const handleImageUpload = async () => {
    if (uploadedImages.length > 0 && completedCrop && newImageRef.current) {
      getCroppedImageBlob(
        newImageRef.current,
        completedCrop,
        async (croppedImageData) => {
          try {
            const newImageMeta =
              await addListingImage(croppedImageData).unwrap();
            setValue("images", [...imageIds, newImageMeta.id], {
              shouldDirty: true,
            });
            setCurrentImagesMeta([...currentImagesMeta, newImageMeta]);
          } catch (error) {
            setIsUploadError(true);
          }
          setUploadedImages([]);
          setCompletedCrop(null);
          newImageRef.current = null;
        },
      );
    }
  };

  const handleRemoveImage = (imageId: string) => {
    setCurrentImagesMeta(
      currentImagesMeta.filter((imageMeta) => imageMeta.id !== imageId),
    );
    setValue(
      "images",
      imageIds.filter((currentImageId) => currentImageId !== imageId),
      {
        shouldDirty: true,
      },
    );
  };

  // Upload photo
  useEffect(() => {
    handleImageUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedImages, completedCrop, addListingImage]);

  return (
    <Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          sx={{ display: "none" }}
          value={_.join(imageIds, ",")}
          inputProps={register("images")}
          error={!!errors.images}
          helperText={errors.images?.message}
        />
      </Grid>
      {isUploadError && (
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Alert
            severity="error"
            onClose={() => {
              setIsUploadError(false);
            }}
          >
            Error uploading image
          </Alert>
        </Grid>
      )}
      <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
        <ImageUploading
          value={uploadedImages}
          onChange={onNewImageSelected}
          maxNumber={1}
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <ImageList sx={{ width: "100%" }} cols={3} gap={16}>
              {currentImagesMeta.map((imageMeta, index) => (
                <ImageListItem
                  key={imageMeta.id}
                  sx={[
                    { border: 1, borderColor: "divider", position: "relative" },
                    { "&:hover .image-actions": { display: "flex" } },
                  ]}
                >
                  <img
                    src={`${imageMeta.thumbnailUrls[0]}`}
                    alt={`Listing pic ${index + 1}`}
                  />
                  <Box
                    className="image-actions"
                    sx={[
                      {
                        display: "none",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "keeneeLightBlue.main",
                        justifyContent: "center",
                        opacity: 0.8,
                        alignItems: "center",
                      },
                    ]}
                  >
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        handleRemoveImage(imageMeta.id);
                      }}
                      sx={{ height: "auto" }}
                    >
                      <DeleteIcon fontSize="large" />
                    </IconButton>
                  </Box>
                </ImageListItem>
              ))}
              <ImageListItem key="new">
                {uploadedImages.length > 0 && completedCrop ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      border: 2,
                      borderColor: "divider",
                      borderStyle: "dashed",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      flexDirection: "column",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      aspectRatio: 1,
                      border: 2,
                      borderColor: "divider",
                      borderStyle: "dashed",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <AddCircleIcon sx={{ color: "divider" }} fontSize="large" />
                  </Box>
                )}
              </ImageListItem>
            </ImageList>
          )}
        </ImageUploading>

        {uploadedImages.length > 0 && (
          <ImageCropperDialog
            image={newImageRef.current}
            isOpen={uploadedImages.length > 0 && !completedCrop}
            onCropCancelled={() => {
              newImageRef.current = null;
              setUploadedImages([]);
            }}
            onCropFinished={(crop: PercentCrop) => setCompletedCrop(crop)}
          />
        )}
      </Grid>
    </Grid>
  );
};
