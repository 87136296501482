import { Grid, TextField } from "@mui/material";
import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { ListingEditFormValues } from "./ListingEditDialog";

interface ListingEditProductInfoProps {
  register: UseFormRegister<ListingEditFormValues>;
  errors: FieldErrors<ListingEditFormValues>;
}

export const ListingEditProductInfo: React.FC<ListingEditProductInfoProps> = ({
  errors,
  register,
}) => {
  return (
    <Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", flexDirection: "column", pt: 2, pb: 2 }}
        gap={4}
      >
        <TextField
          {...register("details.name")}
          error={!!errors.details?.name}
          label="Listing Title"
          fullWidth={true}
          size="small"
          helperText={errors.details?.name?.message}
        />
        <TextField
          {...register("details.make")}
          error={!!errors.details?.make}
          label="Make/Model/Identifier"
          fullWidth={true}
          size="small"
          helperText={errors.details?.make?.message}
        />
        <TextField
          {...register("details.description")}
          error={!!errors.details?.description}
          label="Description"
          fullWidth={true}
          size="small"
          multiline={true}
          minRows={2}
          maxRows={8}
          helperText={errors.details?.description?.message}
        />
        <TextField
          {...register("details.limitations")}
          error={!!errors.details?.limitations}
          label="Restrictions (Optional)"
          fullWidth={true}
          size="small"
          multiline={true}
          minRows={2}
          maxRows={8}
          helperText={errors.details?.limitations?.message}
        />
        <TextField
          {...register("details.zipCode")}
          error={!!errors.details?.zipCode}
          label="ZIP Code"
          fullWidth={true}
          size="small"
          helperText={errors.details?.zipCode?.message}
        />
      </Grid>
    </Grid>
  );
};
