import { AppBar, Box, Container, Grid, Toolbar } from "@mui/material";

import React from "react";
import { Outlet } from "react-router-dom";
import keeneeLogoWhite from "../keenee-logo.png";

import { MainMenu } from "../components/MainMenu";
import { UserAccountMenu } from "../components/UserAccountMenu";

export const DefaultLayout: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        backgroundColor: "#EBEBEB",
      }}
    >
      <AppBar position="static" component="nav" sx={{ zIndex: 1000 }}>
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 0,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img alt="Keenee Logo" src={keeneeLogoWhite} />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
              }}
            >
              <MainMenu />
            </Box>

            <Box sx={{ flexGrow: 0, pl: 2 }}>
              <UserAccountMenu />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Grid container direction="row" sx={{ flexGrow: 1 }}>
        <Outlet />
      </Grid>
    </Box>
  );
};
