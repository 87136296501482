import { Box, Drawer, Grid } from "@mui/material";
import moment from "moment";
import { useMemo, useState } from "react";

import { AvailabilityActions } from "../components/AvailabilityActions";
import { AvailabilityCalendar } from "../components/AvailabilityCalendar";
import { DataSectionLoading } from "../components/DataSectionLoading";
import { ListingOptionSelector } from "../components/ListingOptionSelector";
import { VendorAnyOrderDetails } from "../components/VendorAnyOrderDetails";
import { VendorReservationDetails } from "../components/VendorReservationDetails";
import { useGetVendorStockUnitsQuery } from "../store/apiSlice";
import { OrderType } from "../types";

export const Availability: React.FC<{}> = () => {
  const [showStock, setShowStock] = useState(true);
  const [selectedListing, setSelectedListing] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [openOrderOrReservationString, setOpenOrderOrReservationString] =
    useState<string | null>(null);

  // Month to start showing data for
  const monthYear = useMemo(() => {
    const thisMonth = moment().startOf("month");
    return `${thisMonth.format("M.YYYY")}`.toLowerCase();
  }, []);

  // Extract the order number itself from the availability string
  const orderOrReservationNumber = useMemo(() => {
    // openOrderString is of the form (K|E).<orderNumber> followed by additional info
    // meaning we should extract and parse the <orderNumber> part
    return openOrderOrReservationString
      ? parseInt(openOrderOrReservationString.split(".")[1], 10)
      : null;
  }, [openOrderOrReservationString]);

  // Get all stock units, including ones for unpublished listings
  const {
    data: allStockUnits,
    isLoading: stockUnitsLoading,
    // isSuccess: allStockUnitsSucccess,
    // isError: allStockUnitsError,
  } = useGetVendorStockUnitsQuery();

  // Handle user actions
  const toggleOrderDrawer = (newOrderOrReservationString: string | null) =>
    setOpenOrderOrReservationString(newOrderOrReservationString);

  const handleOrderOrReservationOpened = (
    orderType: OrderType,
    orderId: number,
  ) => {
    toggleOrderDrawer(
      `${orderType === OrderType.KeeneeOrder ? "K" : "E"}.${orderId}`,
    );
  };

  const handleOrderOrReservationClosed = () => {
    toggleOrderDrawer(null);
  };

  const handleListingUnitSelected = (rentalItemId: string, options: string) => {
    setSelectedListing(rentalItemId);
    setSelectedOption(options);
  };

  // Display only the loading indicator instead of the calendar on first load
  if (stockUnitsLoading) {
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          pt: 8,
          mt: -8,
          zIndex: 1,
          overflow: "hidden",
          backgroundColor: "white",
        }}
      >
        <DataSectionLoading />
      </Box>
    );
  }

  // Data loaded at least once - display the calendar
  // TODO: maybe handle errors in a more direct way than relying on a toast?
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        pt: 8,
        mt: -8,
        zIndex: 1,
        boxSizing: "border-box",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        xs={12}
        item
        sx={{
          display: "flex",
          flexGrow: 0,
          maxHeight: "120px",
          borderColor: "rgba(0, 0, 0, 0.12)!important",
          borderBottom: 1,
        }}
      >
        <Box
          sx={{
            width: "290px",

            display: "flex",
          }}
        >
          <Grid container sx={{ alignItems: "center" }}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyItems: "stretch",
                height: "100%",
                pl: 2,
                pr: 2,
                backgroundColor: "white",
              }}
            >
              <ListingOptionSelector
                allStockUnits={allStockUnits}
                onListingUnitSelected={handleListingUnitSelected}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "80vw", flexGrow: 1, display: "flex", pl: 2, pr: 2 }}>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <AvailabilityActions
                onChangeShowStock={(currentShowStock) =>
                  setShowStock(currentShowStock)
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid
        xs={12}
        item
        sx={{
          display: "flex",
          position: "relative",
          width: "100%",
          alignItems: "stretch",
          justifyContent: "stretch",
          justifyItems: "stretch",
          alignContent: "stretch",
        }}
      >
        <AvailabilityCalendar
          showStock={showStock}
          startMonth={monthYear}
          allStockUnits={allStockUnits}
          selectedListing={selectedListing}
          selectedOption={selectedOption}
          onOpenOrderOrReservation={handleOrderOrReservationOpened}
        />
      </Grid>

      <Drawer
        anchor="right"
        open={openOrderOrReservationString !== null}
        onClose={handleOrderOrReservationClosed}
      >
        {openOrderOrReservationString?.startsWith("K.") &&
          orderOrReservationNumber && (
            <VendorAnyOrderDetails
              orderNumber={orderOrReservationNumber}
              onClose={handleOrderOrReservationClosed}
            />
          )}
        {openOrderOrReservationString?.startsWith("E.") &&
          orderOrReservationNumber && (
            <VendorReservationDetails
              orderNumber={orderOrReservationNumber}
              onClose={handleOrderOrReservationClosed}
            />
          )}
      </Drawer>
    </Box>
  );
};
