import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PercentCrop,
} from "react-image-crop";

interface ImageCropperProps {
  image: HTMLImageElement;
  onCropFinished: (crop: PercentCrop) => void;
  onCropCancelled: () => void;
}

export const ImageCropper: React.FC<ImageCropperProps> = ({
  image,
  onCropFinished,
  onCropCancelled,
}) => {
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PercentCrop>();

  useEffect(() => {
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        1,
        image.width,
        image.height,
      ),
      image.width,
      image.height,
    );
    setCrop(crop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <ReactCrop
          crop={crop}
          onChange={(c) => setCrop(c)}
          aspect={1}
          minHeight={100}
          minWidth={100}
          onComplete={(c, pc) => setCompletedCrop(pc)}
        >
          <img src={image.currentSrc} alt="Uploaded file" />
        </ReactCrop>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "flex-end" }}
        gap={1}
      >
        <Button
          variant="contained"
          disabled={!completedCrop}
          onClick={() => {
            if (completedCrop) {
              onCropFinished(completedCrop);
            }
          }}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          disabled={!completedCrop}
          onClick={() => {
            if (completedCrop) {
              onCropCancelled();
            }
          }}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};
