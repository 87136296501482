import { Box, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const MainMenu = () => {
  const navItems = [
    {
      label: "Dashboard",
      url: "/",
    },
    { label: "Availability", url: "/availability" },
    { label: "Listings", url: "/listings" },
    { label: "Messages", url: "/messages" },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        justifyContent: "flex-end",
        flexDirection: "row",
        display: "flex",
      }}
    >
      {navItems.map((item) => (
        <Button
          key={item.label}
          component={RouterLink}
          to={item.url}
          sx={{ color: "white", display: "flex", flexGrow: 0 }}
        >
          {item.label}
        </Button>
      ))}
    </Box>
  );
};
