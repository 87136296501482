import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useState } from "react";
import { NewBookingDialog } from "./NewBookingDialog";

interface AvailabilityActionsProps {
  onChangeShowStock: (newShowStock: boolean) => void;
}

export const AvailabilityActions: React.FC<AvailabilityActionsProps> = ({
  onChangeShowStock,
}) => {
  const [viewMode, setViewMode] = useState("stock");
  const [newReservationDialogOpen, setNewReservationDialogOpen] =
    React.useState<boolean>(false);
  const handleClickNewReservation = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setNewReservationDialogOpen(true);
  };
  const handleClose = () => {
    setNewReservationDialogOpen(false);
  };

  return (
    <Box
      sx={{
        pl: 2,
        pr: 2,
        display: "flex",
        flexDirection: "row",
      }}
      gap={2}
    >
      <ToggleButtonGroup
        color="info"
        size="small"
        value={viewMode}
        exclusive
        onChange={(event, newValue) => {
          setViewMode(newValue);
          onChangeShowStock(newValue === "stock");
        }}
        aria-label="Platform"
      >
        <ToggleButton value="stock">Stock</ToggleButton>
        <ToggleButton value="orderds">Orders</ToggleButton>
      </ToggleButtonGroup>
      <Button variant="contained" onClick={handleClickNewReservation}>
        New reservation
      </Button>
      {newReservationDialogOpen && (
        <NewBookingDialog
          isOpen={newReservationDialogOpen}
          handleCancelled={handleClose}
          handleOrderCreated={() => {
            handleClose();
          }}
        />
      )}
    </Box>
  );
};
