import { Box, Button } from "@mui/material";
import { OrderType } from "../../types";

interface GridCellOrderOrReservationProps {
  orderType: OrderType;
  isStart?: boolean;
  isEnd?: boolean;
  orderId: number;
  onClick: (orderType: OrderType, orderId: number) => void;
}

export const GridCellOrderOrReservation: React.FC<
  GridCellOrderOrReservationProps
> = ({ orderType, isStart, isEnd, orderId, onClick }) => (
  <Box
    sx={{
      pl: isStart ? "2px" : "0px",
      pr: isEnd ? "2px" : "0px",
      pt: "2px",
      pb: "2px",
      //   backgroundColor:
      //     params.value === "A"
      //       ? "white"
      //       : params.value === undefined
      //         ? "#F5F5F5"
      //         : params.value === null
      //           ? "#F5F5F5"
      //           : "white",
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "stretch",
    }}
  >
    <Box
      sx={{
        backgroundColor:
          orderType === OrderType.KeeneeOrder
            ? "#03A9F4"
            : "rgba(2, 136, 209, 0.30)",
        display: "flex",
        cursor: "pointer",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        borderTopLeftRadius: isStart ? "10px" : "0px",
        borderBottomLeftRadius: isStart ? "10px" : "0px",
        borderTopRightRadius: isEnd ? "10px" : "0px",
        borderBottomRightRadius: isEnd ? "10px" : "0px",
      }}
    >
      <Button
        onClick={() => {
          onClick(orderType, orderId);
        }}
        variant="text"
      >
        {orderType === OrderType.KeeneeOrder ? "K" : "M"}
      </Button>
    </Box>
  </Box>
);
