import { Box } from "@mui/material";

interface GridCellAtLeastPartiallyFullyAvailableProps {
  current: number;
  max: number;
}

export const GridCellAtLeastPartiallyAvailable: React.FC<
  GridCellAtLeastPartiallyFullyAvailableProps
> = ({ current, max }) => (
  <Box
    sx={{
      width: "100%",
      backgroundColor:
        current === max
          ? "#FFD085"
          : current > 0
            ? "keeneeLightBlue.main"
            : "pink",
    }}
  >
    <Box className="stock-current">
      {current} / {max}
    </Box>
    <Box className="stock-booked">{current}</Box>
  </Box>
);
