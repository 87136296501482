import { Avatar, Box, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../store/userSlice";
import {
  ConversationMessage as ConversationMessageType,
  UserPublicInformation,
} from "../types";
import { UserAvatar } from "./UserAvatar";

interface ConversationMessageProps {
  message: ConversationMessageType;
  replier: UserPublicInformation;
}

export const ConversationMessage: React.FC<ConversationMessageProps> = ({
  message,
  replier,
}) => {
  const user = useSelector(selectUser);
  const isOwnMessage = useMemo(
    () => message.senderId === user?.id,
    [user, message],
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Grid
        container
        xs={12}
        sx={{ flexDirection: isOwnMessage ? "row-reverse" : "row" }}
      >
        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          {message.isSystemMessage ? (
            <Avatar />
          ) : isOwnMessage ? (
            <Avatar alt={user?.username} src={user?.avatarImage || undefined} />
          ) : (
            <UserAvatar user={replier} />
          )}
        </Grid>

        <Grid
          item
          xs={10}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
          }}
          gap={1}
        >
          <Grid
            container
            xs={12}
            sx={{ justifyContent: isOwnMessage ? "flex-end" : "flex-start" }}
          >
            <Grid item xs={6}>
              {message.isSystemMessage
                ? "Keenee"
                : isOwnMessage
                  ? "You"
                  : replier.username}
            </Grid>
            <Grid item xs={2} sx={{ textAlign: "right" }}>
              {moment(message.date).fromNow()}
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sx={{ justifyContent: isOwnMessage ? "flex-end" : "flex-start" }}
          >
            <Grid item xs={8}>
              <Paper
                sx={{
                  padding: 2,
                  backgroundColor: isOwnMessage
                    ? "keeneeLightBlue.main"
                    : "divider",
                }}
              >
                <Typography>{message.messageBody}</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
