import LaunchIcon from "@mui/icons-material/Launch";
import { Button, SxProps, Theme } from "@mui/material";
import {
  DataGridPro,
  gridClasses,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { VendorOrderWithRepresentativeBooking } from "../types";
import { DeliveryChip } from "./DeliveryChip";

interface CurrentVendorOrdersTableProps {
  sx?: SxProps<Theme>;
  orders: VendorOrderWithRepresentativeBooking[];
  isLoading: boolean;
  onOpenOrder: (orderNumber: number) => void;
}

export const CurrentVendorOrdersTable: React.FC<
  CurrentVendorOrdersTableProps
> = ({ orders, isLoading, sx, onOpenOrder }) => {
  const CurrentVendorOrdersTableColumns: GridColDef[] = [
    {
      field: "orderNumber",
      headerName: "Order ID",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params: GridRenderCellParams<any, string>) => {
        return (
          <Button
            size="small"
            variant="outlined"
            endIcon={<LaunchIcon />}
            onClick={() => {
              onOpenOrder(params.row.orderNumber);
            }}
          >
            {params.value}
          </Button>
        );
      },
    },
    {
      field: "listings",
      headerName: "Order",
      flex: 3,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        return (
          params.row.bookings &&
          (params.row.bookings.length === 1
            ? params.row.bookings[0].listingTitle
            : `${params.row.firstRepresentativeBooking.listingTitle} + ${params.row.bookings.length - 1} other`)
        );
      },
    },
    {
      field: "client",
      headerName: "Client",
      flex: 5,
    },
    {
      field: "deliveryType",
      headerName: "Delivery",
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value && <DeliveryChip deliveryType={params.value} />,
      flex: 2,
    },
  ];

  return (
    <DataGridPro
      loading={isLoading}
      rows={orders}
      rowSelection={false}
      autoHeight={true}
      hideFooter={true}
      columns={CurrentVendorOrdersTableColumns}
      getRowId={(row) => row.orderNumber}
      sx={{
        [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
          outline: "transparent",
        },
        [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]:
          {
            outline: "none",
          },
      }}
    />
  );
};
