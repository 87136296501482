import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

export const GridCellNoAvailability: React.FC<{}> = () => (
  <Box
    sx={{
      width: "100%",
      backgroundColor: "#F5F5F5",
      color: "#DDD",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <CloseIcon fontSize="small" />
  </Box>
);
