import { Box } from "@mui/material";

interface GridCellListingTitleWithOptionsProps {
  listingId: string;
  listingTitle: string;
  options: string;
  isPublic: boolean;
}

export const GridCellListingTitleWithOptions: React.FC<
  GridCellListingTitleWithOptionsProps
> = ({ listingId, isPublic, listingTitle, options }) => (
  <Box
    sx={{ display: "flex", flexDirection: "column", lineHeight: "1rem" }}
    key={listingId}
  >
    <Box
      component="span"
      className="listing-title"
      sx={{
        fontWeight: "bold",
        display: "flex",
        fontSize: "12px",
        color: isPublic === true ? "black" : "gray",
      }}
    >
      {listingTitle}
    </Box>
    <Box
      sx={{ display: "flex", fontSize: "12px" }}
      component="span"
      className="listing-option"
    >
      {options}
    </Box>
  </Box>
);
