import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";

interface VendorReservationLineItemsTableProps {
  order: Record<string, any>;
}

export const VendorReservationLineItemsTable: React.FC<
  VendorReservationLineItemsTableProps
> = ({ order }) => (
  <TableContainer
    sx={{
      borderRadius: 2,
      border: 1,
      borderColor: "divider",
    }}
  >
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Listing</TableCell>
          <TableCell sx={{ textAlign: "center" }}>Quantity</TableCell>
          <TableCell>Options</TableCell>
          <TableCell>Dates</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {_.sortBy(order.bookings, (booking) =>
          moment(booking.bookingStartDate).toDate(),
        ).map((booking: Record<string, any>) => (
          <TableRow
            key={booking.id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {booking.listingTitle}
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              {booking.quantity || 1}
            </TableCell>
            <TableCell>
              {booking.selectedOptions
                ? _.map(
                    booking.selectedOptions,
                    (optionValue: string, optionName: string) => (
                      <Typography
                        key={`${optionName}: ${optionValue}`}
                      >{`${optionName}: ${optionValue}`}</Typography>
                    ),
                  )
                : "-"}
            </TableCell>
            <TableCell>
              {`${moment(booking.reservationDates[0]).format(
                "MMM D",
              )} - ${moment(_.last(booking.reservationDates)).format("MMM D, YYYY")}`}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
