import { Box, Typography } from "@mui/material";

interface GridCellUnitIdProps {
  unitId: string;
}

export const GridCellUnitId: React.FC<GridCellUnitIdProps> = ({ unitId }) => (
  <Box
    sx={{
      display: "flex",
      paddingLeft: "44px",
      height: "100%",
      alignItems: "center",
    }}
    key={unitId}
  >
    <Typography variant="body2" component="span">
      {unitId}
    </Typography>
  </Box>
);
