import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorOutline } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Grid, Stack, TextField, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import * as Yup from "yup";
import keeneeLogo from "../keenee-logo.svg";
import UserService from "../services/UserService";
import { selectUser } from "../store/userSlice";

export const VendorWelcomeMessage = () => (
  <Box sx={{ maxWidth: "600px" }}>
    <Typography variant="body2" gutterBottom align="center">
      To register as a Keenee Business Vendor and gain access to the listings
      management dashboard please complete this form.
    </Typography>
  </Box>
);

export const VendorThankYouMessage = () => (
  <Box>
    <Paper>
      <Box
        sx={{
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        gap={2}
      >
        <Typography
          variant="body1"
          gutterBottom
          align="center"
          sx={{ maxWidth: "600px" }}
        >
          Thank you for registering as a Keenee Business Vendor. We will verify
          your details and email you once completed.
        </Typography>
      </Box>
    </Paper>
  </Box>
);

export function VendorRegistration() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [vendorRegistered, setVendorRegistered] = useLocalStorage(
    "vendor_registered",
    0,
  );

  useEffect(() => {
    // redirect to home if already logged in
    if (user !== null) {
      console.warn("Redirecting to home from registration");
      navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type FormValues = {
    keenee_email: string;
    company_name: string;
    registered_company_address: string;
    tax_id: string;
    trading_name?: string;
    trading_address?: string;
    contact_person: string;
    phone_number: string;
    estimated_number_of_listings: string;
    main_product_genre: string;
    maximum_distance_miles?: number;
  };

  // form validation rules
  const validationSchema = Yup.object().shape({
    keenee_email: Yup.string().required("Your Keenee login E-mail is required"),
    company_name: Yup.string().required("The name of your company is required"),
    registered_company_address: Yup.string().required(
      "Your registered address is required",
    ),
    tax_id: Yup.string().required("Your company's tax ID is required"),
    trading_name: Yup.string().optional(),
    trading_address: Yup.string().optional(),
    contact_person: Yup.string().required(
      "The full name of the contact person is required",
    ),
    phone_number: Yup.string().required(
      "The phone number at which we can reach you is required",
    ),
    estimated_number_of_listings: Yup.string()
      .required("The number of listings you expect to publish is required")
      .min(1, "Must be at least 1"),
    main_product_genre: Yup.string().required("Genre of business is required"),
    maximum_distance_miles: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value,
      )
      .typeError("Amount must be a number")
      .optional()
      .min(5, "Minimum distance is 5 miles"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, setError, formState } =
    useForm<FormValues>(formOptions);
  const { errors } = formState;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await UserService.registerVendor(data);
      setVendorRegistered(1);
    } catch (error) {
      let message = "Unknown Error during registration";
      if (error instanceof Error) {
        message = error.message;
      }
      setError("root", { message: message });
      return;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "keeneeLightBlue.main",
        width: "100%",
        minHeight: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ padding: 4 }} maxWidth="60vw">
        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ mb: 2 }}
        >
          <Grid item xs={12}>
            <Box textAlign={"center"}>
              <img src={keeneeLogo} alt="Keenee Logo" />
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            {vendorRegistered ? (
              <VendorThankYouMessage />
            ) : (
              <VendorWelcomeMessage />
            )}
          </Grid>
        </Grid>

        {!vendorRegistered && (
          <Paper>
            <Grid container direction="row" justifyContent="center">
              <Grid item xs={12}>
                <Box>
                  <Stack
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    spacing={4}
                    sx={{ padding: 6 }}
                  >
                    <Grid
                      item
                      display={"flex"}
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        <Typography pt={1}>E-mail address</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          {...register("keenee_email")}
                          error={!!errors.keenee_email}
                          fullWidth={true}
                          size="small"
                          helperText={
                            errors.keenee_email?.message ||
                            "This should be the email address you use to log in to the Keenee app"
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      display={"flex"}
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        <Typography pt={1}>Registered company name</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          {...register("company_name")}
                          error={!!errors.company_name}
                          fullWidth={true}
                          size="small"
                          helperText={
                            errors.company_name?.message ||
                            "This should be the name of the company you do business as"
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      display={"flex"}
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        <Typography pt={1}>Registered address</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          {...register("registered_company_address")}
                          error={!!errors.registered_company_address}
                          fullWidth={true}
                          multiline={true}
                          minRows={3}
                          size="small"
                          helperText={
                            errors.registered_company_address?.message
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      display={"flex"}
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        <Typography pt={1}>Tax ID</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          {...register("tax_id")}
                          error={!!errors.tax_id}
                          fullWidth={true}
                          size="small"
                          helperText={errors.tax_id?.message}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      display={"flex"}
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        <Typography pt={1}>
                          Trading name
                          <br />
                          <small>(if different than registered)</small>
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          {...register("trading_name")}
                          error={!!errors.trading_name}
                          fullWidth={true}
                          size="small"
                          helperText={errors.trading_name?.message}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      display={"flex"}
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        <Typography pt={1}>
                          Trading address
                          <br />
                          <small>(if different than registered)</small>
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          {...register("trading_address")}
                          error={!!errors.trading_address}
                          fullWidth={true}
                          multiline={true}
                          minRows={3}
                          size="small"
                          helperText={errors.trading_address?.message}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      display={"flex"}
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        <Typography pt={1}>Contact person</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          {...register("contact_person")}
                          error={!!errors.contact_person}
                          fullWidth={true}
                          size="small"
                          helperText={
                            errors.contact_person?.message ||
                            "The name of the person we can get in touch with if needed"
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      display={"flex"}
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        <Typography pt={1}>Contact phone</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          {...register("phone_number")}
                          error={!!errors.phone_number}
                          fullWidth={true}
                          size="small"
                          helperText={
                            errors.phone_number?.message ||
                            "The name of the person we can get in touch with if needed"
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      display={"flex"}
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        <Typography pt={1}>Estimated # of listings</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          {...register("estimated_number_of_listings")}
                          error={!!errors.estimated_number_of_listings}
                          fullWidth={true}
                          size="small"
                          helperText={
                            errors.estimated_number_of_listings?.message ||
                            "The estimated number of listings you wish to post on Keenee"
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      display={"flex"}
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        <Typography pt={1}>Genre of business</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          {...register("main_product_genre")}
                          error={!!errors.main_product_genre}
                          fullWidth={true}
                          size="small"
                          helperText={
                            errors.main_product_genre?.message ||
                            "Please specify the main category of items you would like to offer on Keenee"
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      display={"flex"}
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        <Typography pt={1}>Radius of operation</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          {...register("maximum_distance_miles")}
                          error={!!errors.maximum_distance_miles}
                          fullWidth={true}
                          type="number"
                          size="small"
                          helperText={
                            errors.maximum_distance_miles?.message ||
                            "The maximum distance (in miles) from your location within which you would like to offer your products (users outside this radius will not see your listings)"
                          }
                        />
                      </Grid>
                    </Grid>

                    {errors.root && (
                      <Alert
                        icon={<ErrorOutline fontSize="inherit" />}
                        severity="error"
                      >
                        {errors?.root?.message}
                      </Alert>
                    )}
                    <Grid
                      item
                      display={"flex"}
                      direction="row"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <LoadingButton
                          fullWidth={true}
                          variant="contained"
                          type="submit"
                          loading={formState.isSubmitting}
                        >
                          <span>Register</span>
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Box>
    </Box>
  );
}
