import { TabContext, TabList } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import { useMemo, useState } from "react";
import { useTabs } from "../hooks";
import {
  useGetConversationsQuery,
  useMarkConversationReadMutation,
} from "../store/apiSlice";
import { ConversationMessage as ConversationMessageType } from "../types";

import { useAutoRefresh } from "../hooks/useAutoRefresh";
import { ConversationMessageForm } from "./ConversationMessageForm";
import { ConversationMessageStream } from "./ConversationMessageStream";
import { ConversationStream } from "./ConversationStream";
import { DataSectionEmpty } from "./DataSectionEmpty";
import { DataSectionLoading } from "./DataSectionLoading";

export const Conversations: React.FC<{}> = () => {
  const { currentTab, handleChangeTab } = useTabs("new");
  const [conversationsIdsReadNow, setConversationsIdsReadNow] = useState<
    string[]
  >([]);
  const [conversationMessages, setConversationMessages] = useState<
    ConversationMessageType[]
  >([]);
  const [openConversationId, setOpenConversationId] = useState<string | null>(
    null,
  );

  const {
    allConversations,
    newConversations,
    readConversations,
    archivedConversations,
    isLoading,
    refetch: refetchMessages,
  } = useGetConversationsQuery(undefined, {
    selectFromResult: ({ data, isLoading, isError, isSuccess }) => {
      return {
        allConversations: data || [],
        newConversations:
          data?.filter((conversation) => !conversation.read) || [],
        readConversations:
          data?.filter((conversation) => conversation.read) || [],
        archivedConversations:
          data?.filter((conversation) => conversation.isArchived) || [],
        isLoading,
        isError,
        isSuccess,
      };
    },
  });

  const { autoRefresh, setAutoRefresh } = useAutoRefresh(refetchMessages);

  const [markConversationRead] = useMarkConversationReadMutation();

  const currentConversations = useMemo(() => {
    switch (currentTab) {
      case "new":
        return newConversations;
      case "read":
        return readConversations;
      case "archived":
        return archivedConversations;
      default:
        return allConversations;
    }
  }, [
    allConversations,
    archivedConversations,
    currentTab,
    newConversations,
    readConversations,
  ]);

  const openConversation = useMemo(() => {
    if (currentConversations && openConversationId) {
      const conv = currentConversations.find(
        (conversation) => conversation.id === openConversationId,
      );
      if (conv) {
        setConversationMessages(conv.messages);
      }
      return conv;
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openConversationId]);

  const handleConversationSelected = (conversationId: string) => {
    setOpenConversationId(conversationId);
    const isRead = currentConversations.find(
      (conversation) => conversation.id === conversationId,
    )?.read;
    if (!isRead && !conversationsIdsReadNow.includes(conversationId)) {
      markConversationRead({ id: conversationId });
      setConversationsIdsReadNow([...conversationsIdsReadNow, conversationId]);
    }
  };

  // Only display the loading indicator on the first load
  if (isLoading && !openConversationId) {
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          pt: 8,
          mt: -8,
          zIndex: 1,
          overflow: "hidden",
          backgroundColor: "white",
        }}
      >
        <DataSectionLoading />
      </Box>
    );
  }

  return (
    <TabContext value={currentTab}>
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          pt: 8,
          mt: -8,
          zIndex: 1,
          overflow: "hidden",
        }}
      >
        <Grid xs={4} sx={{ minWidth: "33%", height: "100%", float: "left" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChangeTab}>
              <Tab label={`All (${allConversations.length})`} value={"all"} />
              <Tab label={`New (${newConversations.length})`} value={"new"} />
              <Tab
                label={`Read (${readConversations.length})`}
                value={"read"}
              />
              <Tab
                label={`Archived (${archivedConversations.length})`}
                value={"archived"}
              />
            </TabList>
          </Box>

          {currentConversations.length > 0 ? (
            <ConversationStream
              selectedConversationId={openConversationId}
              conversations={currentConversations}
              readConversationIds={conversationsIdsReadNow}
              onSelectConversation={handleConversationSelected}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "white",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DataSectionEmpty />
            </Box>
          )}
        </Grid>

        <Grid
          sx={{
            borderColor: "rgba(0, 0, 0, 0.12)!important",
            borderLeft: 1,
            height: "100%",
            marginLeft: "33%",
            background: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          {conversationMessages.length > 0 ? (
            <ConversationMessageStream
              replier={openConversation?.replier}
              conversationMessages={conversationMessages}
            />
          ) : (
            <DataSectionEmpty />
          )}

          <ConversationMessageForm
            sx={{ borderColor: "rgba(0, 0, 0, 0.12)!important", borderTop: 1 }}
            conversationId={openConversationId}
            onNewMessagePosted={(newMessage: ConversationMessageType) => {
              setConversationMessages([newMessage, ...conversationMessages]);
            }}
          />
        </Grid>
      </Box>
    </TabContext>
  );
};
